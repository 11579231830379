// Generated by Framer (241a4b4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["il_kyjuTU", "sCVpINxz4"];

const variantClassNames = {il_kyjuTU: "framer-v-1alb818", sCVpINxz4: "framer-v-wq3t6p"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"device-mobile/dark": "il_kyjuTU", "device-mobile/light": "sCVpINxz4"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "il_kyjuTU"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "il_kyjuTU", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-f3Xoe", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1alb818", className)} data-framer-name={"device-mobile/dark"} layoutDependency={layoutDependency} layoutId={"il_kyjuTU"} ref={ref} style={{...style}} {...addPropertyOverrides({sCVpINxz4: {"data-framer-name": "device-mobile/light"}}, baseVariant, gestureVariant)}><SVG className={"framer-b88s1w"} data-framer-name={"Icon"} layout={"position"} layoutDependency={layoutDependency} layoutId={"jonWAXJKU"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 14 22\"><path d=\"M 11.108 0.632 C 12.562 0.632 13.74 1.81 13.74 3.264 L 13.74 19.056 C 13.74 20.51 12.562 21.688 11.108 21.688 L 3.212 21.688 C 1.758 21.688 0.58 20.51 0.58 19.056 L 0.58 3.264 C 0.58 1.81 1.758 0.632 3.212 0.632 Z M 7.16 19.056 C 7.887 19.056 8.476 18.467 8.476 17.74 C 8.476 17.013 7.887 16.424 7.16 16.424 C 6.433 16.424 5.844 17.013 5.844 17.74 C 5.844 18.467 6.433 19.056 7.16 19.056 Z\" fill=\"rgb(46,80,97)\"></path></svg>"} svgContentId={255031921} withExternalLayout {...addPropertyOverrides({sCVpINxz4: {svg: "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 14 22\"><path d=\"M 11.108 0.632 C 12.562 0.632 13.74 1.81 13.74 3.264 L 13.74 19.056 C 13.74 20.51 12.562 21.688 11.108 21.688 L 3.212 21.688 C 1.758 21.688 0.58 20.51 0.58 19.056 L 0.58 3.264 C 0.58 1.81 1.758 0.632 3.212 0.632 Z M 7.16 19.056 C 7.887 19.056 8.476 18.467 8.476 17.74 C 8.476 17.013 7.887 16.424 7.16 16.424 C 6.433 16.424 5.844 17.013 5.844 17.74 C 5.844 18.467 6.433 19.056 7.16 19.056 Z\" fill=\"var(--token-37fdf73e-7920-4262-9a18-f01ddc1216b0, rgb(137, 161, 173)) /* {&quot;name&quot;:&quot;Theme Colors/Neutral/04&quot;} */\"></path></svg>", svgContentId: 3386967652}}, baseVariant, gestureVariant)}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-f3Xoe [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-f3Xoe .framer-xywc46 { display: block; }", ".framer-f3Xoe .framer-1alb818 { height: 27px; overflow: hidden; position: relative; width: 27px; }", ".framer-f3Xoe .framer-b88s1w { flex: none; height: 22px; left: 6px; position: absolute; top: 2px; width: 14px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 27
 * @framerIntrinsicWidth 27
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"sCVpINxz4":{"layout":["fixed","fixed"]}}}
 */
const FramerdSWSaI6cJ: React.ComponentType<Props> = withCSS(Component, css, "framer-f3Xoe") as typeof Component;
export default FramerdSWSaI6cJ;

FramerdSWSaI6cJ.displayName = "Icon/Solid/device-mobile";

FramerdSWSaI6cJ.defaultProps = {height: 27, width: 27};

addPropertyControls(FramerdSWSaI6cJ, {variant: {options: ["il_kyjuTU", "sCVpINxz4"], optionTitles: ["device-mobile/dark", "device-mobile/light"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerdSWSaI6cJ, [])